import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { InputAdornment, IconButton } from "@mui/material";
import { LockOutlined, VisibilityOutlined, VisibilityOff, PersonOutline } from "@mui/icons-material";
import UserService from "../Services/UserService";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../context/user.context";
import { useNavigate } from "react-router-dom"

export default function LoginUser() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { user, updateUser } = useContext(UserContext);
    const [gError, setgError] = useState("");

    const [formData, setFormData] = useState({
        UserName: '',
        Password: '',
    });

    useEffect(() => {
        if (user)
            navigate("/delivery");

    }, [user, navigate])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClick = (event) => {
        event.preventDefault();

        UserService.login(formData)
            .then(function (response) {

                if (!response.data.deliveryComp) {
                    setgError("ليس لديك صلاحية للدخول .");
                    return;
                }

                updateUser({
                    UserName: response.data.UserName,
                    token: response.data.access_token
                });

                navigate("/delivery");

            })
            .catch((e) => { setgError(e.response.data.error) });
    };


    return (

        <div className=" pt-40">

            <div className="card max-w-lg mx-auto">
                <img src="/images/ezone.png" alt="logo" className="max-h-12" />
                <h2 className="text-center font-bold">تسجيل الدخول</h2>
                <form onSubmit={handleClick}>
                    <div className=" flex flex-col gap-6 pt-12">
                        <TextField
                            name="UserName"
                            value={formData.UserName}
                            onChange={handleInputChange}
                            label="إسم المستخدم"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonOutline />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            name="Password"
                            value={formData.Password}
                            onChange={handleInputChange}
                            type={showPassword ? 'text' : 'password'}
                            label="كلمة المرور"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockOutlined />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOff /> : <VisibilityOutlined />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            gError && <div className="invalid-feedback">{gError}</div>

                        }
                        <div className="flex justify-between pb-20">
                            <Link href="#">مستخدم جديد ؟</Link>
                            <Link href="#">نسيت كلمة المرور</Link>
                        </div>
                        <Button type="submit" variant="contained">تسجيل الدخول</Button>
                    </div>
                </form>
            </div>

        </div>

    );










}