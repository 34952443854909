import { axiosInstance } from "../../../Interceptors/http-interceptor";

const shopRoute = "Shop/"


export default class DeliveryService {


    static getOfflinePaymentsReact = (data, status) => { return axiosInstance.post(`${shopRoute}getOfflinePaymentsReact?status=${status}`, data); }
    static setOfflinePaidReact = (data) => { return axiosInstance.post(`${shopRoute}setOfflinePaidReact`, data); }
    static setOfflineCancelledReact = (data) => { return axiosInstance.post(`${shopRoute}setOfflineCancelledReact`, data); }
    static setOfflineShopPaidReact = (data, dMethod) => { return axiosInstance.post(`${shopRoute}setOfflineShopPaidReact?dMethod=${dMethod}`, data); }
    static setOfflinePaymentNoteReact = (notes, Id) => { return axiosInstance.post(`${shopRoute}setOfflinePaymentNoteReact?payId=${Id}`, notes); }


}