/* eslint-disable jsx-a11y/anchor-has-content */
import { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import ShopService from '../../Services/ShopServices';

export default function ShopsSec() {



    const [shopList, setShopList] = useState([]);

    useEffect(() => {

        ShopService.getWebsiteShops().then(response => response.data).then(data => {
            setShopList(data);
        });
    }, []);

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    const logoURL = 'https://ik.imagekit.io/a01bjbmceb/Logos/'; // Define logoURL here

    const itemTemplate = (shop, index) => {
        return (
            <div className="CarouselItems">

                <div style={{ minHeight: '400px', display: 'flex' }} className="uk-card uk-card-default uk-card-hover uk-card-body uk-text-center uk-border-rounded flex-col justify-between">
                    <img src={logoURL + shop.Logo} alt="logo" className="block mx-auto my-auto" style={{ maxHeight: '180px' }} />
                    <h3>{shop.Name}</h3>
                    <ul className="uk-child-width-auto uk-grid-small uk-flex-center" uk-grid="true">
                        <li><a href={'https://' + shop.Link} target="_blank" rel="noreferrer" className="uk-icon-button"><img src="/images/ezone.png" alt="" style={{ height: '25px' }} /></a></li>
                        {shop.Instagram && <li><a href={'https://instagram.com/' + shop.Instagram} target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="instagram"></a></li>}
                        {shop.Facebook1 && <li><a href={'https://facebook.com/' + shop.Facebook1} target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="facebook"></a></li>}
                    </ul>
                </div>

            </div>
        );
    };

    return (
        <section className="uk-section uk-section-muted uk-section-large">
            <div className="uk-container">
                <div className="uk-margin-large">
                    <h2 className="uk-h1 uk-heading-line uk-text-center uk-text-secondary"><span>متاجر على منصة إيزون</span></h2>
                </div>
                <Carousel value={shopList} responsiveOptions={responsiveOptions} itemTemplate={itemTemplate} numVisible={4} numScroll={2} circular={true} autoplayInterval={3000} showIndicators={true} orientation="horizontal" verticalViewPortHeight="500px" />
            </div>
        </section>
    );


}