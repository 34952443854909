import { axiosInstance } from "../../../Interceptors/http-interceptor";

export default class UserService {


    static login = (data) => {
        const formData = new URLSearchParams();
        formData.append('username', data.UserName);
        formData.append('password', data.Password);
        formData.append('grant_type', 'password');

        const headers = { 'apiKey': "c087d609d3b24454b8c5b83a712b4016ece5578ef8e645caba6bc3e45d0f0a1d" }

        return axiosInstance.post(`auth`, formData, { headers });
    }



}