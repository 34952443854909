import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
//import App from './App';
//import Main from './Main/Main'
import Router from "./Router";
//import "primereact/resources/themes/lara-light-cyan/theme.css";
import './assets/css/uikit.theme.ezone-rtl.min.css';
import { arSD } from '@mui/x-data-grid/locales';
import { UserContextProvider } from "./context/user.context.js";
import AxiosErrorHandler from "./Interceptors/http-interceptor.js"


const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontSize: 16,
  },
},
  arSD
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <AxiosErrorHandler>
          <Router />
        </AxiosErrorHandler>
      </UserContextProvider>
    </ThemeProvider>
  </CacheProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
