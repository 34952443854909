import { Carousel } from 'primereact/carousel';

export default function PartnersSec() {


    const partnerList = [
        { href: 'https://www.moamalat.net/', src: '/images/logos-05.png' },
        { href: 'https://masarat.ly/', src: '/images/logos-03.png' },
        { href: 'https://masarat.ly/', src: '/images/logos-04.png' },
        { href: 'https://sadad.almadar.ly/', src: '/images/logos-08.png' },
        { href: 'https://masarat.ly/', src: '/images/logos-09.png' },
        { href: 'https://vanex.ly/', src: '/images/partners/logo-6.png' },
        { href: 'https://sabil.ly/', src: '/images/partners/logo-55.png' },
        { href: 'https://masarat.ly/', src: '/images/logos-01.png' },
        { href: 'https://flowerofcity.com/', src: '/images/partners/logo-54.png' },
        { href: 'https://masarat.ly/', src: '/images/partners/logo-13.png' },
    ];

    const responsiveOptions = [
        { breakpoint: '1024px', numVisible: 3, numScroll: 3 },
        { breakpoint: '768px', numVisible: 2, numScroll: 2 },
        { breakpoint: '560px', numVisible: 1, numScroll: 1 }
    ];

    const itemTemplate = (partner) => (
        <div className="CarouselItems">
            <a href={partner.href} target="_blank" rel="noopener noreferrer">
                <img src={partner.src} alt="moamalat logo" />
            </a>
        </div>
    );

    return (
        <section className="uk-section uk-section-default">
            <div>
                <h2 className="uk-h1 uk-text-center uk-text-secondary uk-margin-large"><span>شركاء النجاح</span></h2>
                <Carousel value={partnerList} responsiveOptions={responsiveOptions} itemTemplate={itemTemplate}
                    numVisible={4} numScroll={2} circular autoplayInterval={3000} indicators="true" orientation="horizontal"
                    verticalViewPortHeight="500px" />
            </div>
        </section>
    );
}