import { useState, useEffect } from "react";
import ShopService from '../Services/ShopServices';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import CircularProgress from '@mui/material/CircularProgress';




export default function ShopRegisterForm() {

    const mobilePattern = /^09(1|2|3|4)[0-9]{7}/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [formData, setFormData] = useState({
        Name: '',
        CategoryId: '',
        Mobile: '',
        Email: '',
        UserName: '',
        Password: '',
        verifyCode: ''
    });

    const [submitted, setSubmitted] = useState(false);
    const [codeSubmitted, setCodeSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [generalError, setGeneralError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [isConfirmCode, setIsConfirmCode] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        ShopService.getShopCats()
            .then(response => response.data)
            .then(data => {
                setCategoryList(data); // Update categoryList state with fetched data
                setLoading(false); // Update loading state to false after fetching data
            })
            .catch(error => setGeneralError(error.response.data.Message));

        const sessionId = localStorage.getItem('sess') || ShopService.emptySessionId;

        if (sessionId !== ShopService.emptySessionId) {
            ShopService.getShopSessionUser(sessionId)
                .then(response => response.data)
                .then(data => {
                    if (data)
                        setFormData(prevFormData => {
                            return {
                                ...prevFormData,
                                UserName: data
                            };
                        });
                    else
                        createShopSession();
                })
                .catch(error => setGeneralError(error.response.data.Message));
        }
        else
            createShopSession();



    }, []);

    function createShopSession() {
        ShopService.CreateShopSession().then(response => response.data)
            .then(data => {
                ShopService.StoreSessionId(data.SessionId);
                setFormData(prevFormData => {
                    return {
                        ...prevFormData,
                        UserName: data.UserName
                    };
                });
            })
            .catch(error => setGeneralError(error.response.data.Message));
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const refreshUser = () => {
        createShopSession();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitted(true);

        if (hasErrors()) {
            setGeneralError('يجب إصلاح الأخطاء في الصفحة');
            return;
        }

        setLoading(true);

        const token = await executeRecaptcha('SendShopOtp');

        ShopService.SendEzonelyOtpReact({ phoneNo: formData.Mobile, reason: 1, googleToken: token })
            .finally(() => setLoading(false))
            .then(response => response.data)
            .then(data => {
                setIsConfirmCode(true);
            })
            .catch(error => setGeneralError(error.response.data.Message));
    };

    function hasErrors() {
        let error = !formData.Name;
        error ||= !formData.Mobile;
        error ||= !mobilePattern.test(formData.Mobile);
        error ||= !formData.Email;
        error ||= !emailPattern.test(formData.Email);
        error ||= !formData.UserName;
        error ||= !formData.Password;
        error ||= formData.Password.length < 6;

        return error;
    }

    const ConfirmCode = async (event) => {
        event.preventDefault();

        setCodeSubmitted(true);

        if (!formData.verifyCode)
            return;

        setLoading(true);

        let token = await executeRecaptcha('ConfirmShopOtp');

        ShopService.ConfirmEzoneOtpReact({ phoneNo: formData.Mobile, otp: formData.verifyCode, googleToken: token })
            .finally(() => setLoading(false))
            .then(response => response.data)
            .then(async data => {

                token = await executeRecaptcha('CreateEzoneShop');

                setLoading(true);

                ShopService.CreateEzoneShopReact({
                    ...formData,
                    googleToken: token
                })
                    .finally(() => setLoading(false))
                    .then(response => response.data)
                    .then(data => {

                        setLoading(true);

                        var passToken = btoa(formData.UserName + ':' + formData.Password);

                        ShopService.SetSessionLogin(passToken, localStorage.getItem('sess'))
                            .finally(() => setLoading(false))
                            .then(response => response.data)
                            .then(data => {
                                var url = 'https://' + formData.UserName;
                                url += '.ezone.ly/admin-login?sessionId=' + localStorage.getItem('sess');

                                window.location.href = url;
                            }).catch(error => setCodeError(error.response.data.Message));

                    }).catch(error => setCodeError(error.response.data.Message));

            })
            .catch(error => setCodeError(error.response.data.Message));
    };


    return (
        <form className="uk-width-xlarge@m uk-margin-auto@m" onSubmit={handleSubmit}>

            <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                    <span className="inputLabel">إسم المتجر</span>

                    <input
                        type="text"
                        name="Name"
                        value={formData.Name}
                        onChange={handleInputChange}
                        className={`register-input uk-form-large ${submitted && !formData.Name && 'is-invalid'}`}
                    />
                    {submitted && !formData.Name && (
                        <div className="invalid-feedback">
                            <div>الرجاء إدخال إسم المتجر</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                    <span className="inputLabel">نشاط المتجر</span>
                    <select
                        name="CategoryId"
                        value={formData.CategoryId}
                        onChange={handleInputChange}
                        className={`block min-w-full register-input ${submitted && !formData.CategoryId && 'is-invalid'}`}
                    >
                        <option value="">إختر ...</option>
                        {categoryList.map((category) => (
                            <option key={category.Id} value={category.Id}>{category.CatName}</option>
                        ))}
                    </select>
                    {submitted && !formData.CategoryId && (
                        <div className="invalid-feedback">
                            <div>الرجاء إختيار تصنيف المتجر</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                    <span className="inputLabel">رقم الهاتف</span>
                    <input
                        type="tel"
                        name="Mobile"
                        value={formData.Mobile}
                        onChange={handleInputChange}
                        className={`register-input ${submitted && !formData.Mobile && 'is-invalid'}`}
                        placeholder="09XXXXXXXX *"
                    />
                    {submitted && (
                        <div className="invalid-feedback">
                            {!formData.Mobile && <div>الرجاء إدخال رقم الهاتف</div>}
                            {formData.Mobile && !mobilePattern.test(formData.Mobile) && <div>الرجاء إدخال رقم الهاتف بصورة صحيحة بداية من 09</div>}
                        </div>
                    )}
                </div>
            </div>
            <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                    <span className="inputLabel">البريد الإلكتروني</span>
                    <input
                        type="email"
                        name="Email"
                        value={formData.Email}
                        onChange={handleInputChange}
                        className={`register-input ${submitted && !formData.Email && 'is-invalid'}`}
                    />
                    {submitted && (
                        <div className="invalid-feedback">
                            {!formData.Email && <div>الرجاء إدخال البريد الإلكتروني</div>}
                            {formData.Email && !emailPattern.test(formData.Email) && <div>الرجاء إدخال البريد الإلكتروني بشكل صحيح</div>}
                        </div>
                    )}
                </div>
            </div>
            <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                    <span className="inputLabel">إسم مستخدم (مؤقت)</span>
                    <div className="flex items-center">
                        <div className="flex-grow">
                            <input
                                type="text"
                                name="UserName"
                                value={formData.UserName}
                                onChange={handleInputChange}
                                className={`register-input ${submitted && !formData.UserName && 'is-invalid'}`}
                                readOnly
                            />
                        </div>
                        <div>
                            <RefreshIcon onClick={refreshUser} className="cursor-pointer" style={{ fontSize: '30px', color: 'gray' }} />
                        </div>
                    </div>
                    {submitted && !formData.UserName && (
                        <div className="invalid-feedback">
                            <div>الرجاء إعادة تحميل الصفحة للحصول على إسم مستخدم مؤقت</div>
                        </div>
                    )}
                    <div>
                        <span className="text-xs text-gray-500">وأيضاً سيكون رابط متجرك مؤقتاً </span>
                        <span className="text-xs text-gray-500">https://{formData.UserName}.ezone.ly </span>
                        <span className="text-xs text-gray-500">ويمكنك تغييره فيما بعد.</span>
                    </div>
                </div>
            </div>
            <div className="uk-margin">
                <div className="uk-inline uk-width-1-1">
                    <span className="inputLabel">كلمة المرور</span>
                    <input
                        type="password"
                        name="Password"
                        value={formData.Password}
                        onChange={handleInputChange}
                        className={`register-input ${submitted && !formData.Password && 'is-invalid'}`}
                    />
                    {submitted && (
                        <div className="invalid-feedback">
                            {!formData.Password && <div>الرجاء إدخال كلمة المرور</div>}
                            {formData.Password && formData.Password.length < 6 && <div>كلمة المرور يجب أن تتكون من 6 أحرف أو أرقام على الأقل</div>}
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div className="uk-margin text-sm">
                    <span>من خلال الضغط على زر "أنشيء متجرك" فإنك توافق على </span>
                    <Link to="/privacy-policy" target="_blank">سياسة الخصوصية</Link>
                    <span> و </span>
                    <Link to="/store-contract" target="_blank">شروط الإستخدام</Link>
                    <span> .</span>
                </div>
                {!isConfirmCode && (
                    <div className="uk-margin-medium">

                        {/* <div className="mb-4 flex flex-row-reverse" id="recaptcha-container"></div> */}
                        {generalError && <div className="invalid-feedback mb-4">{generalError}</div>}
                        <button

                            disabled={loading}
                            className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                        >
                            <div className="flex items-center justify-center">
                                <span className="ml-4">انشيء متجرك</span>
                                {loading && <CircularProgress size={20} />}
                            </div>
                        </button>
                        <div className="uk-margin">
                            <span> لديك حساب في إيزون؟</span>
                            <Link className="mr-2" to="/shop-login">تسجيل الدخول</Link>
                        </div>
                    </div>
                )}
                {isConfirmCode && (
                    <fieldset className="uk-margin-medium">
                        <legend>التحقق من الهاتف</legend>
                        <div className="uk-inline uk-width-1-1">
                            <span className="inputLabel">رمز التحقق</span>
                            <input
                                type="password"
                                name="verifyCode"
                                value={formData.verifyCode}
                                onChange={handleInputChange}
                                className={`register-input ${codeSubmitted && !formData.verifyCode && 'is-invalid'}`}
                            />
                            <span className="text-xs text-gray-500">سيصلك رمز التحقق كرسالة إلى الهاتف</span>
                            {codeSubmitted && !formData.verifyCode && (
                                <div className="invalid-feedback">
                                    <div>الرجاء إدخال رمز التحقق المرسل لهاتفك</div>
                                </div>
                            )}
                            {codeError && <div className="invalid-feedback">{codeError}</div>}
                        </div>
                        <div className="uk-margin-medium max-w-xs mx-auto">
                            <button
                                onClick={ConfirmCode}
                                disabled={loading}
                                className="uk-button uk-button-primary uk-button-large uk-width-1-1"
                            >
                                <div className="flex items-center justify-center">
                                    <span className="ml-4">تأكيد</span>
                                    {loading && <CircularProgress size={20} />}
                                </div>
                            </button>
                        </div>
                    </fieldset>
                )}
            </div>

        </form>
    );

}