/* eslint-disable jsx-a11y/anchor-is-valid */
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CustomerForgetPassForm from '../Parts/CustomerForgetPassForm'



export default function CustomerForgetPassword() {


    return (
        <section className="uk-section uk-section-secondary uk-preserve-color uk-background-cover uk-background-norepeat uk-flex uk-flex-middle uk-text-center"
            style={{ backgroundImage: "url('/images/login-page-bg.jpg')" }}
            uk-height-viewport="offset-top: true">
            <div className="uk-width-1-1">
                <div className="uk-container uk-container-xsmall">
                    <div className="uk-margin">
                        <a className="uk-navbar-item uk-logo">
                            <img src="/images/logo-inverse.svg" alt="eZone logo" />
                        </a>
                    </div>

                    <GoogleReCaptchaProvider reCaptchaKey="6LceJOwfAAAAAMk6vxOejKtnVob_eChw0XcYuaWN">
                        <CustomerForgetPassForm />
                    </GoogleReCaptchaProvider>

                </div>
            </div>
        </section>



    );




}