import { axiosInstance } from "../../../Interceptors/http-interceptor";

const shopRoute = "Shop/"
const userRoute = "zoneUsers/"


export default class ShopService {

    static emptySessionId = '00000000-0000-0000-0000-000000000000';
    static SessionId = this.emptySessionId;

    static StoreSessionId(sessionId) {
        localStorage.setItem('sess', sessionId);
        this.SessionId = sessionId;
    }

    static getWebsiteShops = () => { return axiosInstance.get(`${shopRoute}getWebsiteShops`); }
    static getShopCats = () => { return axiosInstance.get(`${shopRoute}getShopCats`); }



    static shopLogin = (data) => { return axiosInstance.post(`${shopRoute}ShopLoginReact`, data); }
    static CreateShopSession = () => { return axiosInstance.post(`${shopRoute}CreateShopSession?oldSessionId=${this.SessionId}`); }
    static SetSessionLogin = (token, sessionId) => { return axiosInstance.post(`${shopRoute}SetSessionLogin?sessionId=${sessionId}&sessionToken=${token}`); }


    static getShopSessionUser = (sessionId) => { return axiosInstance.get(`${shopRoute}getShopSessionUser?sessionId=${sessionId}`); }


    static SendEzonelyOtpReact = (data) => { return axiosInstance.post(`${userRoute}SendEzonelyOtpReact`, data); }
    static ConfirmEzoneOtpReact = (data) => { return axiosInstance.post(`${userRoute}ConfirmEzoneOtpReact`, data); }
    static CreateEzoneShopReact = (data) => { return axiosInstance.post(`${shopRoute}CreateEzoneShopReact`, data); }
    static resetEzoneUserReact = (data) => { return axiosInstance.post(`${userRoute}resetEzoneUserReact`, data); }

    


}