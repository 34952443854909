import { Outlet } from "react-router-dom";
import WebFooter from "./Parts/WebFooter";
import WebHeader from "./Parts/WebHeader";

export default function HomeMain(){



    return(
        <div>
            <WebHeader></WebHeader>           

            <div className="main-body">
                <Outlet></Outlet>
            </div>

            <WebFooter></WebFooter>
            
        </div>
    );



}