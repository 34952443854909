import { Link } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-has-content */
export default function WebFooter() {



    return (
        <>
            <footer className="uk-section uk-section-secondary uk-backgroud-cover" style={{ backgroundImage: "url('/images/section-bg-02.png')" }}>
                <div className="uk-container">
                    <div className="tm-grid-expand uk-grid-large uk-margin-medium-top" uk-grid="true">
                        <div className="uk-width-1-1 uk-width-1-2@m">
                            <div className="uk-text-center uk-text-right@m">
                                <Link to="/"><img src="/images/logo-inverse.svg" className="inline-block" alt="eZone" /></Link>
                                <p>إيزون علامة تجارية ليبية، تأسست في عـام 2021 م تهدف إلى تمكين قطاع التجزئة من الدخول إلى عالم
                                    التجزئة الإلكترونية بطريقة سهلة واحترافية.
                                </p>
                            </div>
                            <hr className="uk-divider-icon uk-margin-medium uk-hidden@m" />
                        </div>
                        <div className="uk-width-1-1 uk-width-1-4@m uk-text-center uk-text-right@m">
                            <h3 className="uk-text-primary uk-preserve-color">عن إيزون</h3>
                            <ul className="uk-list uk-margin-medium">
                                <li><Link to="/about-ezone" className="uk-link-text">عن إيزون</Link></li>
                                <li><Link to="/our-partners" className="uk-link-text">شركاء النجاح</Link></li>
                                <li><Link to="/privacy-policy" className="uk-link-text">سياسة الخصوصية</Link></li>
                                <li><Link to="/store-contract" className="uk-link-text">الشروط والأحكام</Link></li>
                            </ul>
                        </div>
                        <div className="uk-width-1-1 uk-width-1-4@m uk-text-center uk-text-right@m">
                            <h3 className="uk-text-primary uk-preserve-color">تواصل معنا</h3>
                            <ul className="uk-list uk-margin-medium">
                                <li><a href="tel:00218918829999" className="uk-link-text"><span uk-icon="phone"></span>
                                    00218928829999</a></li>
                                <li><a href="tel:00218918829999" className="uk-link-text"><span uk-icon="phone"></span>
                                    00218918829999</a></li>
                                <li><a href="mailto:hi@ezone.ly" className="uk-link-text"><span uk-icon="mail"></span> hi@ezone.ly</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr className="uk-divider-icon uk-margin-medium" />
                    <div className="uk-flex-between uk-flex-middle" uk-grid="true">
                        <div className="uk-width-1-1 uk-width-auto@m uk-text-center">
                            <a href="/shop-login" className="uk-button uk-button-text uk-button-large">تسجيل الدخول</a>
                        </div>
                        <div className="uk-width-1-1 uk-width-auto@m uk-text-center">
                            <ul className="uk-child-width-auto uk-grid-small uk-flex-inline" uk-grid="true">
                                <li><a href="https://facebook.com/ezonely" target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="facebook" title="Facebook"></a></li>
                                <li><a href="https://www.instagram.com/ezone.ly/" target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="instagram"></a></li>
                                <li><a href="https://www.linkedin.com/company/ezone-ly/" target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="linkedin"></a></li>
                                <li><a href="https://www.youtube.com/channel/UCqfXQpCpnzLW6seOthU-wgw" target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="youtube"></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>            
        </>

    );


}