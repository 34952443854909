import './ShopRegister.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ShopRegisterForm from '../Parts/ShopRegisterForm'



export default function ShopRegister() {
    return (


        <div className="uk-grid-collapse uk-child-width-1-2@s uk-flex-stretch" uk-grid="true" uk-height-viewport="offset-top: true">
            <div className="uk-background-cover uk-padding-large uk-flex uk-flex-middle uk-visible@m"
                uk-height-viewport="offset-top: true" style={{ backgroundImage: "url('/images/section-bg-03.jpg')" }}>
                <div>
                    <h1 className="uk-text-primary uk-preserve-color">إيزون</h1>
                    <p className="uk-text-lead uk-light">
                        إنتقل من التجارة التقليدية إلى التجارة الإلكترونية بكل يسر وأنشيء متجرك الخاص خلال دقائق وعزز تجارتك
                        بالأدوات التي توفرها لك منصة إيزون.
                    </p>
                </div>
            </div>
            <div className="uk-padding-large">
                <GoogleReCaptchaProvider reCaptchaKey="6LceJOwfAAAAAMk6vxOejKtnVob_eChw0XcYuaWN">
                    <ShopRegisterForm />
                </GoogleReCaptchaProvider>
            </div>
        </div>

    );

}