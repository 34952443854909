import HomeCoverSec from "../Parts/Home/HomeCoverSec";
import PartnersSec from "../Parts/Home/PartnersSec";
import ServicesSec from "../Parts/Home/ServicesSec";
import ShopsSec from "../Parts/Home/ShopsSec";
import SignInSec from "../Parts/Home/SignInSec";
import WhyEzoneSec from "../Parts/Home/WhyEzoneSec";

export default function HomePage(){



    return(
        <div>
            <HomeCoverSec></HomeCoverSec>
            <WhyEzoneSec></WhyEzoneSec>
            <ServicesSec></ServicesSec>
            <SignInSec></SignInSec>
            <ShopsSec></ShopsSec>
            <PartnersSec></PartnersSec>            
        </div>
    );



}