/* eslint-disable jsx-a11y/anchor-has-content */
import { useState, useEffect } from 'react';
import ShopService from '../Services/ShopServices';


export default function EzoneShops() {
    const [shopList, setShopList] = useState([]);

    useEffect(() => {

        ShopService.getWebsiteShops().then(response => response.data).then(data => {
            setShopList(data);
        });
    }, []);


    const logoURL = 'https://ik.imagekit.io/a01bjbmceb/Logos/';

    return (
        <div>
            <section className="uk-section uk-section-secondary uk-section-large uk-backgroud-contain uk-background-norepeat uk-background-image@m" style={{ backgroundImage: `url(${logoURL})` }}>
                <div className="uk-container uk-container-small uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                    <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                        <h2 className="uk-heading-small">متاجر إيزون</h2>
                        <p className="uk-text-lead">مكان يجمع المتاجر الإلكترونية الموجودة حاليا داخل السوق الليبي يوفر جميع الخدمات التي يحتاجها المتجر من وسائل دفع ووسائل توصيل والعديد من الخدمات تساعد الزبون للوصول للمنتج واقتنائه بسهولة كبيرة.</p>
                    </div>
                </div>
            </section>
            <div className="uk-section uk-section-muted uk-section-large">
                <div className="uk-container uk-container-large">
                    <div className="uk-margin-medium-top">
                        <div className="uk-grid-medium uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-4@m" uk-grid="true">
                            {shopList.map(shop => (
                                <div key={shop.id} style={{ minHeight: '400px', display: 'flex !important' }} className="uk-card uk-card-default uk-card-hover uk-card-body uk-text-center uk-border-rounded flex-col justify-between">
                                    <div className="flex-grow flex items-center">
                                        <img style={{ maxHeight: '180px' }} className="block mx-auto my-auto" src={logoURL + shop.Logo} alt="logo" />
                                    </div>
                                    <div>
                                        <h3>{shop.Name}</h3>
                                        <ul className="uk-child-width-auto uk-grid-small uk-flex-center" uk-grid="true">
                                            <li><a href={'https://' + shop.Link} target="_blank" rel="noreferrer" className="uk-icon-button"><img src="/images/ezone.png" alt="" style={{ height: '25px' }} /></a></li>
                                            {shop.Instagram && <li><a href={'https://instagram.com/' + shop.Instagram} target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="instagram"></a></li>}
                                            {shop.Facebook1 && <li><a href={'https://facebook.com/' + shop.Facebook1} target="_blank" rel="noreferrer" className="uk-icon-button" uk-icon="facebook"></a></li>}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};