import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function YesNoDialog({ open, handleClose, title, content, onConfirm }) {


    const handleConfirm = () => {
        onConfirm(); // Call the onConfirm callback function when the user confirms
        handleClose(); // Close the dialog
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>إلغاء الأمر</Button>
                <Button onClick={handleConfirm} autoFocus>
                    موافق
                </Button>
            </DialogActions>
        </Dialog>
    );
};