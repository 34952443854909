/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import './WebHeader.css'
import ScrollToTop from "../../../Utility/ScrollToTop";
import { useState } from "react";

export default function WebHeader() {

    const [divStyles, setDivStyles] = useState({ display: 'none' });
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {

        if (!isVisible) {
            setIsVisible(true);
            setDivStyles({ display: 'block' })
        } else {
            setIsVisible(false);
        }
    };

    return (

        <div>
            <header className="uk-header-mobile uk-hidden@l">
                <div className="uk-navbar-container">
                    <div className="uk-container">
                        <nav className="uk-navbar" data-uk-navbar>
                            <div className="uk-navbar-right">
                                <Link className="uk-navbar-item uk-logo" to="/">
                                    <img src="images/logo.svg" alt="eZone logo" />
                                </Link>
                            </div>
                            <div className="uk-navbar-left">
                                <a className="uk-navbar-toggle" onClick={toggleVisibility}>
                                    <span data-uk-navbar-toggle-icon></span>
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <header className="uk-header uk-visible@l" data-uk-header>
                <div data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top" media="@l" cls-active="uk-navbar-sticky" sel-target=".uk-navbar-container" className="uk-sticky">
                    <div className="uk-navbar-container">
                        <div className="uk-container uk-container-large">
                            <ScrollToTop />
                            <nav className="uk-navbar" data-uk-navbar>
                                <div className="uk-navbar-right">
                                    <Link className="uk-navbar-item uk-logo" to="/">
                                        <img src="images/logo.svg" alt="eZone logo" />
                                    </Link>
                                </div>
                                <div className="uk-navbar-center">
                                    <ul className="uk-navbar-nav">
                                        <li className="uk-active"><Link to="/">الرئيسية</Link></li>
                                        <li><Link to="/about-ezone">عن إيزون</Link></li>
                                        <li><Link to="/our-clients">متاجر إيزون</Link></li>
                                        <li><Link to="/our-partners">شركاء النجاح</Link></li>
                                        <li><Link to="/say-hi">إتصل بنا</Link></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-left">
                                    <div className="uk-navbar-item">
                                        <div className="uk-grid-collapse" data-uk-grid>
                                            <div><Link className="uk-button uk-link-reset" to="/shop-login">تسجيل الدخول</Link></div>
                                            <div><Link className="uk-button uk-button-primary" to="/register">أنشىء متجرك الأن</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            {/* Offcanvas */}
            {
                <div className={'uk-hidden@l ezone-offcanvas uk-open ezone-slide-' + (isVisible ? 'left' : 'right')} style={divStyles}>
                    <div className="uk-offcanvas-bar uk-flex uk-flex-column ">
                        <button className="uk-offcanvas-close uk-close-large" type="button" data-uk-close onClick={toggleVisibility}></button>
                        <Link className="uk-logo" to="/"><img src="/images/logo.svg" alt="eZone logo" /></Link>
                        <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical uk-nav-parent-icon" data-uk-nav >
                            <li onClick={toggleVisibility}><Link to="/">الرئيسية</Link></li>
                            <li onClick={toggleVisibility}><Link to="/about-ezone">عن إيزون</Link></li>
                            <li onClick={toggleVisibility}><Link to="/our-clients">متاجر إيزون</Link></li>
                            <li onClick={toggleVisibility}><Link to="/our-partners">شركاء النجاح</Link></li>
                            <li onClick={toggleVisibility}><Link to="/say-hi">إتصل بنا</Link></li>
                        </ul>
                        <div data-uk-nav >
                            <Link  onClick={toggleVisibility} className="uk-button uk-button-primary" to="/shop-login">تسجيل الدخول</Link>
                        </div>
                    </div>
                </div>
            }
        </div>
    );



}