import './DeliveryHeader.css'
import { useContext, useState } from 'react';
import { UserContext } from "../../../context/user.context";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";

export default function DeliveryHeader() {
    const { user, logOut } = useContext(UserContext);
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {

        if (!isVisible)
            setIsVisible(true);
        else
            setIsVisible(false);

    };

    return (
        <>

            <div className='delivery-header'>
                <div className='delivery-burger cursor-pointer'>
                    <MenuIcon fontSize="large" onClick={toggleVisibility}></MenuIcon>
                </div>
                <div className='delivery-header-logo'>
                    <img src={'/images/partners/' + user.UserName + '.png'} alt="logo" />
                </div>
            </div>

            <div>
                <div id="sidebar" className={'delivery-sidebar dSideBar-slide-' + (isVisible ? 'left' : 'right')} >
                    <div onClick={toggleVisibility}>
                        <Link name="New" to="/delivery?status=1" >شحنات جديدة</Link>
                        <Link name="OnProcess" to="/delivery?status=2" >شحنات تحت التسوية</Link>
                        <Link name="Settled" to="/delivery?status=3" >شحنات تمت تسويتها</Link>
                        <Link name="Cancelled" to="/delivery?status=4" >شحنات ملغية</Link>
                    </div>
                    <div className="pb-4">
                        <Link name="logout" to="/delivery/login" onClick={() => { logOut() }}>تسجيل الخروج</Link>
                    </div>
                </div>
            </div>
        </>

    );


}