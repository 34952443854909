import "./ServiceSec.css"
export default function ServicesSec() {



    return (
        <div className="uk-section uk-section-secondary uk-preserve-color uk-backgroud-cover"
            style={{ backgroundImage: "url('/images/section-bg-02.png')" }}>
            <div className="uk-container uk-container-small">
                <div className="uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                    <h2 className="uk-heading-small uk-text-primary">خدماتنا</h2>
                    <div className="uk-text-lead uk-light">
                        نـعمل على المساعـدة لـ توفـيـر الوقت المسـتـغرق فـي التـنـقـل للزبــون والتســويق لتـوســيع
                        الانتشار وادارة الطـلـبات للتاجر بتسـهـيل البـيـع والشــراء بطــرق الـدفـع المتـنـوعــة، وتـسـريـع
                        التــوصــيل وذلـك لـحـرصــنا عـلـى تـقــديـم حــل واحـد لجـمـيـع المـدفــوعــات فــي مــكــان واحــد
                    </div>
                </div>
            </div>
            <div className="uk-container">
                <div className="uk-margin-large-top services-grid">
                    <div className="uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-5@m uk-grid-match uk-margin-large-top uk-text-center"
                        uk-grid="true" uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 250;">
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-card-small uk-border-rounded">
                                <img src="/images/services-447.png" alt="" />
                                <h5>انشاء متجر خاص بـالـتاجـر وهـــويـتـه</h5>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-card-small uk-border-rounded">
                                <img src="/images/services-445.png" alt="" />
                                <h5>عــــــرض مـنـتــجاتـــك بعدة طرق والــوان واشــــكــال مـمــيــزه</h5>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-card-small uk-border-rounded">
                                <img src="/images/services-444.png" alt="" />
                                <h5>تـسـويـق منـتـجـات الــتــجــار بـالـإضافة إلى حملات تسويقية متقدمة</h5>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-card-small uk-border-rounded">
                                <img src="/images/services-446.png" alt="" />
                                <h5>وسائل الدفع الإلكترونية</h5>
                            </div>
                        </div>
                        <div>
                            <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-card-small uk-border-rounded">
                                <img src="/images/services-443.png" alt="" />
                                <h5>أكثر من أربع شركات توصيل</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );



}