export default function ContactEzone() {
    return (
        <>
            <section className="uk-section uk-section-secondary uk-section-large uk-backgroud-contain uk-background-norepeat uk-background-image@m" style={{ backgroundImage: "url('/images/section-bg-01.png')" }}>
                <div className="uk-container uk-container-small uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                    <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                        <h1 className="uk-heading-small">إتصل بنا</h1>
                        <p className="uk-text-lead">
                            نحن متواجدون من أجلك ، بغض النظر عما إذا كانت لديك أسئلة أو مخاوف. يرجى الاتصال بنا وسيساعدك أحد ممثلي
                            دعم العملاء لدينا. مكالمتك مهمة جدًا بالنسبة لنا !
                        </p>
                    </div>
                </div>
            </section>
            <div className="uk-section uk-section-default">
                <div className="uk-container">
                    <div className="uk-margin-large-top">
                        <div className="uk-grid-large uk-flex-middle" uk-grid="true">
                            <div className="uk-width-1-2@m uk-flex-last@m">
                                <img src="/images/contact-image.svg" alt="" />
                            </div>
                            <div className="uk-width-1-2@m">
                                <h2>إتصل بنا</h2>
                                <hr className="uk-divider-small" />
                                <div className="uk-h3">
                                    اتصل بنا إما عبر الهاتف أو الفاكس أو البريد الإلكتروني أو عبر واتس أب او سكايب وسيساعدك أحد
                                    موظفينا في مندوبي دعم العملاء.
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto"><span uk-icon="phone"></span></div>
                                        <div className="uk-grid-expand">00218928829999</div>
                                    </div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto"><span uk-icon="phone"></span></div>
                                        <div className="uk-grid-expand">00218918829999</div>
                                    </div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto"><span uk-icon="mail"></span></div>
                                        <div className="uk-grid-expand">hi@ezone.ly</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="uk-divider-icon uk-margin-large" />
                    <div className="uk-margin-large-top ">
                        <div className="uk-grid-large uk-flex-middle uk-grid-divider" uk-grid="true">
                            <div className="uk-width-1-3@m">
                                <img src="/images/newsletter.svg" alt="النشرة البريدية" />
                            </div>
                            <div className="uk-width-2-3@m">
                                <h3 className="uk-h2">اشترك في نشرتنا الإخبارية</h3>
                                <p className="uk-text-large uk-h3 uk-margin-small-top">أضف بريدك الإلكتروني إلى النشرة الإخبارية لتبقيك
                                    على اطلاع بآخر التحديثات وفرص العمل </p>
                                <div className="uk-margin">
                                    <form className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-2-3@m">
                                            <div className="uk-inline uk-width-1-1">
                                                <span className="uk-form-icon uk-form-icon-flip" uk-icon="icon: lock"></span>
                                                <input className="uk-input uk-form-large" type="text" placeholder="البريد الإلكتروني" />
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3@m">
                                            <button className="uk-button uk-button-secondary uk-width-1-1">إشترك الأن</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="uk-section uk-section-muted">
                <div className="uk-container uk-container-small uk-text-center">
                    <h2>لماذا إيزون</h2>
                    <hr className="uk-divider-small" />
                    <div className="uk-h4">
                        مع إيزون تستطيع خلال دقائق إنشاء متجرك الخاص بأقل التكاليف والحصول على استضافة مجانية وتحديثات مستمرة
                        ومتجددة وبدون أي عمولة على المبيعات.
                    </div>
                </div>
            </section>

        </>

    );
}