import React from "react";
import { createContext, useState } from "react";
// Intialize the main State for user
export const UserContext = createContext({
  user: null,
  updateUser: () => { }
});

export const UserContextProvider = ({ children }) => {
  // Initialize user state with localStorage value or null if not available
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);


  // Function to update user state and localStorage
  const updateUser = (newUser) => {
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  const isEzone = () => {
    // Check if UserName matches a predefined value
    return user !== null && user.UserName === "ezone";
  };

  const logOut = () => {
    setUser(null);
    localStorage.removeItem("user")
  }

  // pass value to object
  const userValue = {
    user,
    isEzone,
    updateUser,
    logOut
  };

  // Provide the context with userValue to its children
  return React.createElement(UserContext.Provider, { value: userValue }, children);

};
