/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import ShopService from '../Services/ShopServices';
import './CustomerLogin.css'
import { Link } from 'react-router-dom';



export default function CustomerLogin() {

    const [formData, setFormData] = useState({
        userName: '',
        password: ''
    });

    const [formErrors, setFormErrors] = useState({
        userError: false,
        passError: false,
        formError: ""
    });

    function handleFormChange(event) {
        const { name, value } = event.target;

        setFormData(oldData => ({
            ...oldData,
            [name]: value
        }));

        resetErrors();

    }

    function handleSubmit(e) {
        e.preventDefault();

        resetErrors();

        updateErrors("userError", formData.userName === '');
        updateErrors("passError", formData.password === '');

        if (formData.userName === '' || formData.password === '') {
            return;
        }

        ShopService.shopLogin(formData).then(response => response.data).then(shop => {

            ShopService.CreateShopSession().then(resp => resp.data).then(session => {

                const token = btoa(formData.userName + ':' + formData.password);

                ShopService.SetSessionLogin(token, session.SessionId).then(resp => resp.data).then(loggedIn => {

                    var url = "https://";

                    if (shop.Domain)
                        url += shop.Domain;
                    else
                        url += shop.Link + ".ezone.ly";

                    url += '/admin-login?sessionId=' + session.SessionId;

                    window.location.href = url;

                });

            });


        }).catch(error => {
            updateErrors("formError", error.response.data.Message);
        });

    }

    function updateErrors(name, value) {
        setFormErrors(oldData => ({
            ...oldData,
            [name]: value
        }));
    }

    function resetErrors() {
        setFormErrors({
            userError: false,
            passError: false,
            formError: ""
        });
    }




    return (

        <section className="uk-section uk-section-secondary uk-preserve-color uk-backgroud-cover uk-background-norepeat uk-flex uk-flex-middle uk-text-center" style={{ backgroundImage: "url('/images/login-page-bg.jpg')" }} uk-height-viewport="offset-top: true">
            <div className="uk-width-1-1">
                <div className="uk-container uk-container-xsmall">
                    <div className="uk-margin">
                        <a className="uk-navbar-item uk-logo">
                            <img src="/images/logo-inverse.svg" alt="eZone logo" />
                        </a>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="uk-margin">
                            <div className="uk-inline">
                                <span className="uk-form-icon" uk-icon="icon: users"></span>
                                <input
                                    name="userName"
                                    className="uk-input uk-form-large uk-form-width-large"
                                    type="text"
                                    placeholder="إسم المستخدم"
                                    value={formData.userName}
                                    onChange={handleFormChange}
                                />
                            </div>
                            {formErrors.userError && (<p className="inputLogin">يرجى إدخال اسم المستخدم</p>)}
                        </div>
                        <div className="uk-margin">
                            <div className="uk-inline">
                                <span className="uk-form-icon" uk-icon="icon: lock"></span>
                                <input
                                    name="password"
                                    className="uk-input uk-form-large uk-form-width-large"
                                    type="password"
                                    placeholder="كلمة المرور"
                                    value={formData.password}
                                    onChange={handleFormChange}
                                />
                            </div>
                            {formErrors.passError && <p className="inputLogin">يرجى إدخال كلمة المرور</p>}
                        </div>
                        {formErrors.formError !== '' && <p className="inputLogin">{formErrors.formError}</p>}
                        <div className="uk-margin">
                            <Link to="/shop-forgot-password">نسيت كلمة المرور؟</Link>
                        </div>
                        <div className="uk-margin-medium">
                            <button className="uk-button uk-button-primary uk-button-large">
                                <div className="flex items-center">
                                    <span>تسجيل الدخول</span>
                                    {/* Loading spinner */}
                                </div>
                            </button>
                        </div>
                        <div className="uk-margin">
                            <span className="uk-light">ليس لديك حساب في إيزون؟</span>
                            <Link className="mr-2" to="/register">سجل الأن</Link>
                        </div>
                    </form>
                </div>
            </div>
        </section>



    );


}