import { Outlet } from "react-router-dom";
import DeliveryHeader from "./Parts/DeliveryHeader"
import "./DeliveryMain.css"
import globalRouter from "../../Interceptors/GlobalRouter";
import { useNavigate } from "react-router-dom"


export default function DeliveryMain() {
    
    const navigate = useNavigate();
    globalRouter.navigate = navigate;

    return (

        <div>
            <DeliveryHeader />
            <div className="delivery-body">
                <Outlet></Outlet>
            </div>
        </div>


    );
}