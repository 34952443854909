import axios from "axios";
import globalRouter from "./GlobalRouter";
import {  useContext, useEffect } from "react";
import { UserContext } from "../context/user.context";

export const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? "http://localhost:57304/api/" : "https://data.ezone.ly/api/",
});

const AxiosErrorHandler = ({ children }) => {
  const { logOut } = useContext(UserContext);

  useEffect(() => {

    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          logOut();
          globalRouter.navigate('/delivery/login');
        }
    
        return Promise.reject(error);
      }
    );
  }, [logOut])

  return children
}

export default AxiosErrorHandler

axiosInstance.interceptors.request.use(
  function (config) {

    config.headers["Content-Type"] = 'application/json'
    
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token)
      config.headers.Authorization = `Bearer ${user.token}`;    

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

