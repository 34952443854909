//import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from './context/user.context';





export default function PrivateRoute({ component: Component }) {

    const { user } = useContext(UserContext);

    return user ? Component : <Navigate to="/delivery/login" />
}

