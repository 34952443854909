import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import './CustomerForgetPassForm.css'
import ShopService from '../Services/ShopServices';
import CircularProgress from '@mui/material/CircularProgress';


export default function CustomerForgetPassForm() {


    const mobilePattern = /^09(1|2|3|4)[0-9]{7}/;
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [formData, setFormData] = useState({
        Mobile: '',
        UserName: '',
        Password: '',
        verifyCode: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [IsconfirmCode, setIsConfirmCode] = useState(false);
    const [IsCodeSubmitted, setIsCodeSubmitted] = useState(false);
    const [IsChangePassword, setIsChangePassword] = useState(false);
    const [IsPasswordSubmitted, setIsPasswordSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [GeneralError, setGeneralError] = useState('');
    const [codeError, setCodeError] = useState('');
    const [PasswordError, setPasswordError] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        setSubmitted(true);

        if (hasErrors())
            return;

        setLoading(true);

        const token = await executeRecaptcha('SendShopOtp');

        ShopService.SendEzonelyOtpReact({ phoneNo: formData.Mobile, userName: formData.UserName, reason: 3, googleToken: token })
            .finally(() => setLoading(false))
            .then(response => response.data)
            .then(data => {
                setIsConfirmCode(true);
            })
            .catch(error => setGeneralError(error.response.data.Message));


    };

    function hasErrors() {
        let error = !formData.UserName;
        error ||= !formData.Mobile;
        error ||= !mobilePattern.test(formData.Mobile);
        return error;
    }

    const confirmCode = async (event) => {
        event.preventDefault();

        setIsCodeSubmitted(true);

        if (!formData.verifyCode)
            return;

        setLoading(true);

        const token = await executeRecaptcha('ConfirmShopOtp');


        ShopService.ConfirmEzoneOtpReact({ phoneNo: formData.Mobile, otp: formData.verifyCode, googleToken: token })
            .finally(() => setLoading(false))
            .then(response => response.data)
            .then(async data => {

                setIsChangePassword(true);

            })
            .catch(error => setCodeError(error.response.data.Message));
    };

    const changePassword = async (event) => {

        setIsPasswordSubmitted(true);

        if (!formData.Password || formData.Password.length < 6)
            return;

        setLoading(true);

        const token = await executeRecaptcha('ResetUserPassword');

        ShopService.resetEzoneUserReact({ ...formData, googleToken: token })
            .finally(() => setLoading(false))
            .then(response => response.data)
            .then(() => {

                setLoading(true);

                ShopService.shopLogin(formData).then(response => response.data)
                    .finally(() => setLoading(false))
                    .then(shop => {

                        setLoading(true);

                        ShopService.CreateShopSession().then(resp => resp.data)
                            .finally(() => setLoading(false))
                            .then(session => {

                                const token = btoa(formData.UserName + ':' + formData.Password);

                                ShopService.SetSessionLogin(token, session.SessionId).then(resp => resp.data).then(loggedIn => {

                                    var url = "https://";

                                    if (shop.Domain)
                                        url += shop.Domain;
                                    else
                                        url += shop.Link + ".ezone.ly";

                                    url += '/admin-login?sessionId=' + session.SessionId;

                                    window.location.href = url;

                                });

                            });


                    }).catch(error => setPasswordError(error.response.data.Message));

            })
            .catch(error => setPasswordError(error.response.data.Message));

    };


    return (
        <form onSubmit={onSubmit}>
            {!IsconfirmCode && (
                <>
                    <div className="uk-margin">
                        <div className="uk-inline">
                            <span className="uk-form-icon" uk-icon="icon: users"></span>
                            <input
                                type="text"
                                name="UserName"
                                placeholder="إسم المستخدم"
                                value={formData.UserName}
                                onChange={handleInputChange}
                                className="uk-input uk-form-large uk-form-width-large"
                            />
                        </div>
                        {submitted && !formData.UserName && (
                            <div className="inputLogin">الرجاء إدخال إسم المستخدم</div>
                        )}
                    </div>
                    <div className="uk-margin">
                        <div className="uk-inline">
                            <span className="uk-form-icon" uk-icon="icon: phone"></span>
                            <input
                                type="text"
                                name="Mobile"
                                placeholder="رقم الهاتف"
                                value={formData.Mobile}
                                onChange={handleInputChange}
                                className="uk-input uk-form-large uk-form-width-large"
                            />
                        </div>
                        {submitted && (
                            <div>
                                {!formData.Mobile && <div className="inputLogin">الرجاء إدخال رقم الهاتف</div>}
                                {formData.Mobile && !mobilePattern.test(formData.Mobile) && <div className="inputLogin">الرجاء إدخال رقم الهاتف بصورة صحيحة بداية من 09</div>}
                            </div>
                        )}
                    </div>
                    {submitted && GeneralError && (
                        <div className="inputLogin">
                            {GeneralError}
                        </div>
                    )}
                    <div className="uk-margin-medium">
                        <button disabled={loading} className="uk-button uk-button-primary uk-button-large">
                            <div className="flex items-center">
                                <span className='ml-4'>طلب رمز التحقق</span>
                                {loading && <CircularProgress size={20} />}
                            </div>
                        </button>
                    </div>

                </>
            )}
            {IsconfirmCode && !IsChangePassword && (
                <>
                    <div className="uk-margin">
                        <div className="uk-inline">
                            <span className="uk-form-icon" uk-icon="icon: lock"></span>
                            <input
                                type="text"
                                name="verifyCode"
                                placeholder="رمز التحقق"
                                value={formData.verifyCode}
                                onChange={handleInputChange}
                                className="uk-input uk-form-large uk-form-width-large"
                            />
                        </div>
                        {IsCodeSubmitted && !formData.verifyCode && (
                            <div className="inputLogin">الرجاء إدخال رمز التحقق المرسل لهاتفك</div>
                        )}
                        {codeError && (
                            <div className="inputLogin">{codeError}</div>
                        )}
                        <span className="text-xs text-white block">سيصلك رمز التحقق كرسالة إلى الهاتف</span>
                    </div>
                    <div className="uk-margin-medium">
                        <button onClick={confirmCode} disabled={loading} className="uk-button uk-button-primary uk-button-large">
                            <div className="flex items-center">
                                <span className='ml-4'>تأكيد</span>
                                {loading && <CircularProgress size={20} />}
                            </div>
                        </button>
                    </div>
                </>
            )}
            {IsconfirmCode && IsChangePassword && (
                <>
                    <div className="uk-margin">
                        <div className="uk-inline">
                            <span className="uk-form-icon" uk-icon="icon: lock"></span>
                            <input
                                type="text"
                                name="Password"
                                placeholder="كلمة مرور جديدة"
                                value={formData.Password}
                                onChange={handleInputChange}
                                className="uk-input uk-form-large uk-form-width-large"
                            />
                        </div>
                        {IsPasswordSubmitted && (
                            <div>
                                {!formData.Password && <div className="inputLogin">الرجاء إدخال كلمة المرور</div>}
                                {formData.Password && formData.Password.length < 6 && <div className="inputLogin">كلمة المرور يجب أن تتكون من 6 أحرف أو أرقام على الأقل</div>}
                            </div>
                        )}
                        {PasswordError && (
                            <div className="inputLogin">حدث خطأ أثناء تغيير كلمة المرور ، يرجى التواصل مع الدعم الفني.</div>
                        )}
                    </div>
                    <div className="uk-margin-medium">
                        <button onClick={changePassword} disabled={loading} className="uk-button uk-button-primary uk-button-large">
                            <div className="flex items-center">
                                <span className='ml-4'>إعادة تعيين كلمة المرور</span>
                                {loading && <CircularProgress size={20} />}
                            </div>
                        </button>
                    </div>
                </>
            )}
            <div className="uk-margin">
                <span className="uk-light">تذكرت كلمة المرور ؟</span>
                <Link className="mr-2" to="/shop-login">تسجيل الدخول</Link>
            </div>
            <div className="uk-margin">
                <span className="uk-light">ليس لديك حساب في إيزون؟</span>
                <Link className="mr-2" to="/register">إشترك الأن</Link>
            </div>
        </form>
    );

}