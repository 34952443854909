import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';

export default function DateInput({ label, value, setValue }) {
    const [cleared, setCleared] = useState(false);

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                slotProps={
                    { textField: { size: 'small' }, field: { clearable: true, onClear: () => setCleared(true) } }
                }
                sx={{ width: 210 }}
                format="YYYY/MM/DD"
                value={value}
                onChange={(value) => setValue(value)}
            />
        </LocalizationProvider>
    );
}