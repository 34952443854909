export default function AboutEzone() {

    return (
        <div>
            <section className="uk-section uk-section-secondary uk-section-large uk-background-cover uk-background-blend-luminosity uk-background-norepeat uk-background-image@m" style={{ backgroundImage: "url('/images/section-bg-04.png')" }}>
                <div className="uk-container uk-container-small uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                    <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                        <h1 className="uk-heading-small">عن إيزون</h1>
                        <p className="uk-text-lead">
                            إيزون علامة تجارية ليبية، تأسست في عـام 2021 م تهدف إلى تمكين قطاع التجزئة من الدخول إلى عالم التجزئة
                            الإلكترونية بطريقة سهلة واحترافية.
                        </p>
                    </div>
                </div>
            </section>

            <div className="uk-section uk-section-default">
                <div className="uk-container">
                    <div className="uk-margin-large-top">
                        <div className="uk-grid-large uk-flex-middle" uk-grid="true">
                            <div className="uk-width-1-2@m">
                                <h2>ما هي "منصة إيزون"</h2>
                                <hr className="uk-divider-small" />
                                <p>
                                    هو مكان يستطيع من خلاله  أصحاب المشاريع الصغيرة والمتوسطة من انشاء متجر الكتروني متكامل خاص بهم ,  يتوفر فيه جميع الخدمات التي يحتاجها  من وسائل دفع ووسائل توصيل والعديد من الخدمات تساعد الزبون للوصول للمنتج واقتنائه بسهولة كبيرة جدا
                                    مما يجعل  عملية الربط ما بين التاجر المحلي والزبائن في ليبيا عموماً سهل جدا  بالاضافة الي مساعدة هذه الفئة  في التوسع و الانتشار لخدماتها في التجارة الإلكترونية وللمستهلكين بالاقتصاد الليبي غاية في السهوله .
                                </p>
                            </div>
                            <div className="uk-width-1-2@m">
                                <img src="/images/about.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="uk-margin-large-top uk-tile uk-tile-primary">
                        <p className="uk-h4">شركة طموح للتسويق والخدمات والتجارة الإلكترونية قد اطلقت منصة إيزون لتمكين وتسهيل لعملائها
                            في عمليات التجارة الإلكترونية بكل اريحية. مع إيزون تستطيع خلال دقائق إنشاء متجرك الخاص بأقل التكاليف
                            والحصول على استضافة مجانية وتحديثات مستمرة ومتجددة وبدون أي عمولة على المبيعات.</p>
                    </div>
                </div>
            </div>

            <section className="uk-section uk-section-muted">
                <div className="uk-container">
                    <h2>مميزات إيزون</h2>
                    <hr className="uk-divider-small" />
                    <div className="uk-child-width-1-2@m uk-grid-large uk-margin-large-top" uk-grid="true">
                        <div>
                            <div className="uk-child-width-1-1@m uk-grid-small uk-grid-divider" uk-grid="true">
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            أمتلك متجر بهويتك الخاصة وبأقل التكاليف.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            تصاميم احترافية تناسب ذوقك.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            سهولة إدراج المنتجات وإدارة المخزون.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            أدوات تسويقية لزيادة مبيعاتك ( حملات اعلانية وممولة ورسائل تلقائية تسويقية للزبائن. واعداد فيدوات دعائية والتعريفية لجميع المتاجر)
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            تقارير مفصلة لقياس أداء المتجر.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            تسهيل جميع الاجراءات و الخدمات المصرفية التي يحتاجها التاجر داخل جميع المصارف العاملة في ليبيا.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            دعم جميع وسائل الدفع الإلكتروني و المحافظ الالكتروينة ( البطاقة المحلية لجميع المصارف في ليبيا , موبي كاش ,سداد , ادفع لي, تداول , دينار باى , يسر باى , ناب فور باى , ميزا , تفاني ..)
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-grid-small" uk-grid="true">
                                        <div className="uk-width-auto">
                                            <span className="uk-margin-small-left uk-text-primary" uk-icon="plus-circle" />
                                        </div>
                                        <div className="uk-width-expand">
                                            التعاقد مع اكثر من 4 شركات توصيل ليبية لتسهيل عملية وصول المنتجات لعملائك.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <img src="/images/about-features-01.svg" alt="" />
                        </div>
                    </div>

                </div>
            </section>

        </div>
    );



}