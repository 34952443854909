import { Link } from "react-router-dom";

export default function SignInSec() {



    return (
        <div className="uk-section uk-section-default uk-section-large">
            <div className="uk-container uk-container-small">
                <div className="uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                    <h2 className="uk-heading-small">ابدأ تجربتك المجانية!</h2>
                    <div className="uk-text-lead uk-margin-medium-bottom">يـتيـح لـك زر (إنشاء متجرك) إمـكانية تكـوين مـتـجرك الخـاص لمدة 7 أيام مجاناً بـهـويـته و
                        اسـمـه التجاري. ما عليك إلا تعبئة البيانات المطلوبة و إضافة شعار متجرك الخاص
                    </div>
                    <div className="uk-flex uk-flex-center uk-flex-wrap uk-margin-large-top">
                        <div className="uk-margin-bottom">
                            <Link to="/register" className="uk-button uk-button-primary uk-button-large">قم بإنشاء متجرك</Link>
                        </div>
                        <div className="uk-margin-bottom uk-margin-left pr-10">
                            <Link to="/shop-login" className="uk-button uk-button-default uk-button-large">تسجيل الدخول</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );



}