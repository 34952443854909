export default function WhyEzoneSec() {

    return (
        <div>
            <section className="uk-section uk-section-default">
                <div className="uk-container uk-container-small uk-text-center">
                    <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                        <h2 className="uk-heading-small">
                            لماذا إيزون
                        </h2>
                        <p className="uk-text-lead">
                            لأننا نوفر لك الطرق الأسرع والأسهل لنمو تجارتك الإلكترونية ومساعدتك
                            فــي الــتوســع والــنـجــاح بــشـكـل مـتكـامـل مــن خــلال تـوفــيـر مـــنصــة إيـــزون
                        </p>
                    </div>
                    <div className="uk-flex uk-flex-wrap uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-5@m uk-flex-center uk-margin-large-top"
                        uk-scrollspy="cls: uk-animation-scale-up; target: > div; delay: 250;">
                        <div>
                            <div>
                                <div className="uk-icon-border"><img src="/images/icon-01.png" alt="" /></div>
                                <h4>أستهداف أوسع</h4>
                            </div>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-02.png" alt="" /></div>
                            <h4>لامخازن</h4>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-03.png" alt="" /></div>
                            <h4>لست بحاجة إلى ميزانية</h4>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-04.png" alt="" /></div>
                            <h4>لست بحاجة إلى عمالة</h4>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-05.png" alt="" /></div>
                            <h4>التكلفة المنخفضة</h4>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-06.png" alt="" /></div>
                            <h4>متجر إلكتروني</h4>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-07.png" alt="" /></div>
                            <h4>دعم جميع وسائل الدفع الإلكتروني</h4>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-09.png" alt="" /></div>
                            <h4>تقارير الأداء</h4>
                        </div>
                        <div>
                            <div className="uk-icon-border"><img src="/images/icon-08.png" alt="" /></div>
                            <h4>توفير أغلب وسائل التوصيل</h4>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );



}