/* eslint-disable jsx-a11y/anchor-is-valid */
export default function EzonePartners() {

    return (

        <>
            <section className="uk-section uk-section-secondary uk-section-large uk-backgroud-contain uk-background-norepeat uk-background-image@m" style={{ backgroundImage: `url('/images/section-bg-01.png')` }}>
                <div className="uk-container uk-container-small uk-text-center" uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                    <div uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > *; delay: 250;">
                        <h2 className="uk-heading-small">شركاء النجاح</h2>
                        <p className="uk-text-lead">إيـزون عـــلامـة تـجـاريـة تـأسـسـت فـي عــام 2021 تـهــدف الـي تمكيـن قـطـاع التجزئة من الدخول الى عالم التجزئة الإلكترونـيـة بطريقة سهلة واحترافية</p>
                    </div>
                </div>
            </section>

            <div className="uk-section uk-section-muted uk-section-large">
                <div className="uk-container">
                    <div className="uk-margin-large-top">
                        <ul className="uk-subnav uk-subnav-pill uk-flex-center" uk-switcher="animation: uk-animation-fade">
                            <li><a href="#">المصارف التجارية</a></li>
                            <li><a href="#">شركات الدفع الإلكتروني</a></li>
                            <li><a href="#">شركات الشحن والتوصيل</a></li>
                        </ul>
                        <hr className="uk-margin-medium" />
                        <div className="uk-switcher uk-margin">
                            <div>
                                <div className="uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-4@m uk-grid-match uk-margin-large-top uk-flex-center" uk-grid="true" uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 250;">
                                    <div><div className="uk-card uk-card-default uk-card-hover uk-card-small uk-text-center"><img src="/images/partners/logo-1.png" alt="" /></div></div>
                                    <div><div className="uk-card uk-card-default uk-card-hover uk-card-small uk-text-center"><img src="/images/partners/logo-2.png" alt="" /></div></div>
                                    <div><div className="uk-card uk-card-default uk-card-hover uk-card-small uk-text-center"><img src="/images/partners/logo-3.png" alt="" /></div></div>
                                    <div><div className="uk-card uk-card-default uk-card-hover uk-card-small uk-text-center"><img src="/images/partners/logo-4.png" alt="" /></div></div>
                                    <div><div className="uk-card uk-card-default uk-card-hover uk-card-small uk-text-center"><img src="/images/partners/logo-5.png" alt="" /></div></div>
                                    <div><div className="uk-card uk-card-default uk-card-hover uk-card-small uk-text-center"><img src="/images/partners/logo-8.png" alt="" /></div></div>
                                    <div><div className="uk-card uk-card-default uk-card-hover uk-card-small uk-text-center"><img src="/images/partners/logo-9.png" alt="" /></div></div>
                                </div>
                            </div>

                            <div>
                                <div className="uk-child-width-1-1 uk-margin-large-top uk-flex-center" uk-grid="true" uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 250;">
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-10.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <p>
                                                        هي خدمة يقدمها بنك التجارة والتنمية ، والذي تم إنشاؤه عام 1993 بموجب القرار
                                                        رقم (1) ، لتمكين المستخدمين من الدفع إلكترونيًا عبر التطبيق أو الرسائل
                                                        النصية ، مباشرة من أرصدة حساباتهم المصرفية ، مما يسمح للمستخدمين بالشراء من
                                                        أكثر من 200 متجر محلي في عدة مجالات
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-15.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <p>هي خدمة مقدمة من البنوك الليبية التابعة لشبكة نمو وبرنامج التبديل الوطني
                                                        الليبي ، والتي تضم ما يقرب من 14 مصرفًا ليبيًا ، ومؤخراً تم تفعيل الخدمات
                                                        لتعمل عبر الإنترنت في الشراء عبر الإنترنت ، إلى جانب العمليات الجارية مثل
                                                        التشغيل الآلي. السحب من الصراف الآلي والشراء من خلال نقاط البيع المتوفرة في
                                                        الأسواق والمحلات التجارية.
                                                        يبلغ عدد العملاء الذين يمتلكون بطاقات بنكية محلية حوالي 1.3 مليون عميل ، وقد
                                                        اكتسبت الخدمة شعبية كبيرة مؤخرًا بعد ربط البطاقات المصرفية مباشرة بحسابات
                                                        العملاء ، مما أدى إلى تسهيل استخدامها وإلغاء الحاجة إلى تعبئتها يدويًا.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-11.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <p>خدمة سداد هي خدمة دفع إلكتروني تقدمها شركة المدار الجديد وهي احد مزودي خدمة
                                                        الجوال في ليبيا وهي محفظة إلكترونية تتيح للمستخدمين الدفع بها في مختلف
                                                        المجالات حيث تمكن المستخدم من إعادة شحن رصيده من خلال حسابه المصرفي أو
                                                        الودائع المباشرة من عدة فروع. هي إحدى خدمات شركة المدار الجديد التي تأسست
                                                        عام 1995 وتضم أكثر من 5 ملايين مشترك. أصبحت خدمة سداد واحدة من الخدمات
                                                        الرائدة في مجال التجارة الإلكترونية والمعاملات المصرفية</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-12.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <p>خدمة يُسر باي هي خدمة دفع إلكتروني مقدمة من المصرف التجاري الوطني لعملائه ضمن
                                                        باقة الخدمات المصرفية عبر الهاتف النقال تتيح للزبائن سداد ثمن مشترياتهم،
                                                        وفواتيرهم عن طريق تحويل مالي من حسابهم المصرفي إلى حساب التاجر أو مقدم
                                                        الخدمة.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-13.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <p>خدمة موبي كاش هي خدمة دفع إلكتروني مقدمة من مصرف الوحدة لعملائه عبر الهاتف
                                                        النقال تتيح للزبائن سداد ثمن مشترياتهم، وفواتيرهم عن طريق تحويل مالي من
                                                        حسابهم المصرفي إلى حساب التاجر أو مقدم الخدمة.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-14.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <p>
                                                        خدمة ناب 4 باي هي خدمة دفع إلكتروني مقدمة من مصرف شمال أفريقيا لعملائه ضمن
                                                        باقة الخدمات المصرفية عبر الهاتف النقال تتيح للزبائن سداد ثمن مشترياتهم،
                                                        وفواتيرهم عن طريق تحويل مالي من حسابهم المصرفي إلى حساب التاجر أو مقدم
                                                        الخدمة
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-16.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <p>
                                                        نظام ميزا للمدفوعات هو أحد أنظمة الدفع الإلكتروني، تم إطلاقه عام 2017 كنظام
                                                        مركزي للشراء ودفع الفواتير والتحويلات الأخرى إلكترونياً في ليبيا، تقوم ميزا
                                                        بتسهيل وتسريع مختلف المدفوعات للأفراد والمصارف والشركات والقطاع الحكومي،
                                                        وتستمر في تطوير خدمات جديدة تشمل كافة المدفوعات، وتهدف ميزا الى تقديم حل
                                                        واحد لجميع المدفعات وخاصة في هذه الفترة العصيبة مع عدم توفر السيولة
                                                        النقدية.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uk-child-width-1-1 uk-margin-large-top uk-flex-center" uk-grid="true" uk-scrollspy="cls: uk-animation-fade; target: .uk-card; delay: 250;">
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-6.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <h3 className="uk-text-primary">فانكس</h3>
                                                    <p>بأمانة نفخر بها، وبعلاقة راقيّة نتشرف بها مع عملائنا وبخبرة احترافية نرتكز
                                                        عليها، وسعينا الدؤوب للتطور دائماً، تأتي شركتنا ڤانكس الواعدة في ليبيا؛
                                                        لتواكب السوق وتتماشى مع الرّيادة المبتكرة، نسعى بقدراتنا على أن نكون نموذجًا
                                                        متميزًا ومتكاملًا في تقديم خدمات النقل والشّحن السّريع للشركات النّائشة
                                                        ولأصحاب المتاجر الإلكترونية، وأيضًا إيصال الطّرود البريديّة؛ لنكمل الحاجات
                                                        والرّغبات بطريقة مثاليّة الّتي تطمحون إليها وأكثر.</p>
                                                    <div className="uk-text-left">
                                                        <a href="https://www.vanex.ly" target="_blank" rel="noreferrer" className="uk-button uk-button-secondary uk-button-small">زيارة الموقع</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-7.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <h3 className="uk-text-primary">أميال</h3>
                                                    <p>
                                                        شركة أميال للتوصيل والشحن السريع هي شركة ليبية خاصة بنقل والشحن وشراء عبر
                                                        المواقع الالكترونية والفيس بوك .نقوم بمساعدة و دعم كل اصحاب المشاريع الصغيرة
                                                        وكذلك المشاريع المنزلية .
                                                        نحن لا نختص بالشحن والتوصيل فقط بل نقدم لكم كل ماهو افضل وجديد داخل السوق
                                                        الليبية.
                                                    </p>
                                                    <div className="uk-text-left">
                                                        <a href="https://web.amyal.ly/" rel="noreferrer" target="_blank" className="uk-button uk-button-secondary uk-button-small">زيارة الموقع</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-55.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <h3 className="uk-text-primary">درب السبيل</h3>
                                                    <p>
                                                        هدفنا هو جعل الخدمات اللوجستية ابسط لكل فئة من العملاء نحن نعمل بجد باستمرار
                                                        لجعل نظامنا مؤتمن بالكامل ومنح عملائنا ضمان الموثوقية سلامة المنتجات آمنها
                                                        هي أولويتنا الأولى.
                                                    </p>
                                                    <div className="uk-text-left">
                                                        <a href="https://sabil.ly/" target="_blank" rel="noreferrer" className="uk-button uk-button-secondary uk-button-small">زيارة الموقع</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-hover uk-card-body">
                                            <div className="uk-flex-middle uk-grid-divider" uk-grid="true">
                                                <div className="uk-width-1-4@m uk-text-center">
                                                    <img src="/images/partners/logo-54.png" alt="" />
                                                </div>
                                                <div className="uk-width-expand@m">
                                                    <h3 className="uk-text-primary">زهرة المدائن</h3>
                                                    <p>
                                                        تعد شركة زهرة إحدى الشركات الناشئة التي تقدم خدمات تحويل الاموال محليا ودوليا
                                                        وشحن البضائع في كل أنحاء ليبيا ويسعدنا خدمة عملاءنا الاعزاء بجودة وحرفية
                                                        عاليتين جدا. توفر شركتنا ضمان سلامة البضائع من حيث الضياع والتلف وسوء
                                                        التخزين . كما توفر شركتنا خدمة النقل السريع للبضائع والأمانات على المستوى
                                                        المحلي،هذا وستتوفر خدمة الشحن على المستوى الدولي خلال الفترة القريبة
                                                        القادمة. كما توفر أيضا خدمات التداول المالي بشكل سريع ومريح لزبائننا الكرام
                                                        سواء كانت حوالات على المستوى المحلي أو دوليا.
                                                    </p>
                                                    <div className="uk-text-left">
                                                        <a href="https://flowerofcity.com/" target="_blank" rel="noreferrer" className="uk-button uk-button-secondary uk-button-small">زيارة الموقع</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>





    );


}