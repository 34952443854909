import { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Stack } from '@mui/system';
import DeliveryService from "../Services/DeliveryService";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./Shipments.css";
import { useContext } from "react";
import { UserContext } from "../../../context/user.context";
import YesNoDialog from "../Parts/YesNoDialog";
import NotesOutlined from '@mui/icons-material/NotesOutlined';
import InsertDialog from '../Parts/InsertDialog'
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import DateInput from "../Parts/DateInput";
import { Box } from "@mui/material";




export default function Shipments() {

    const { isEzone } = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [shops, setShops] = useState([]);
    const [ShipmentNo, setShipmentNo] = useState('');
    const [ShopId, setShopId] = useState(null);
    const [DateFrom, setDateFrom] = useState(null);
    const [DateTo, setDateTo] = useState(null);
    const [dMethodCo, setDMethodCo] = useState(null);

    const dMethodList = [
        { dMethod: 7, label: 'dexpress' },
        { dMethod: 8, label: 'wingsly' },
        { dMethod: 9, label: 'skyex' },
        { dMethod: 10, label: 'stpx' },
        { dMethod: 11, label: 'turboex' }
    ]


    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [actionType, setActionType] = useState(null);

    const handleOpenAlertDialog = (action) => {
        setActionType(action); // Set the action type (e.g., 'insert' or 'delete')
        setOpenAlertDialog(true);
    };

    const handleCloseAlertDialog = () => {
        setOpenAlertDialog(false);
    };

    const handleConfirmPaid = () => {

        if (selection.length === 0)
            return;

        setLoading(true);

        DeliveryService.setOfflinePaidReact(selection)
            .finally(() => setLoading(false))
            .then(() => {
                fetchData();
            });



        setAnchorEl(null);
    };

    const handleConfirmShopPaid = () => {

        if (selection.length === 0)
            return;

        if (!dMethodCo)
            return;

        setLoading(true);

        DeliveryService.setOfflineShopPaidReact(selection, dMethodCo?.dMethod)
            .finally(() => setLoading(false))
            .then(() => {
                fetchData();
            });



        setAnchorEl(null);
    };

    const handleConfirmCancelled = () => {

        if (selection.length === 0)
            return;

        setLoading(true);

        DeliveryService.setOfflineCancelledReact(selection)
            .finally(() => setLoading(false))
            .then(() => {
                fetchData();
            });


        setAnchorEl(null);
    };


    const [openInsertNoteDialog, setOpenInsertNoteDialog] = useState(false);
    const [InsertRowId, setInsertRowId] = useState(null);
    const [CurrentNote, setCurrentNote] = useState(null);

    const handleOpenInsertNoteDialog = (row) => {

        const data = rows.find(x => x.Id === row.value)

        if (!data.ShopName)
            return;

        setInsertRowId(data.Id);
        setCurrentNote(data.Notes)
        setOpenInsertNoteDialog(true);
    };

    const handleCloseInsertDialog = () => {
        setOpenInsertNoteDialog(false);
    };

    const handleConfirmInsertDialog = (text) => {

        setLoading(true);

        DeliveryService.setOfflinePaymentNoteReact(text, InsertRowId)
            .finally(() => setLoading(false))
            .then(() => {
                const index = rows.findIndex(item => item.Id === InsertRowId);
                if (index !== -1) {
                    const newRows = [...rows]; // Create a copy of the array
                    newRows[index] = { ...newRows[index], Notes: text }; // Update the Notes property
                    // Set the state with the updated array
                    setRows(newRows);
                }
                setInsertRowId(null);
                setCurrentNote(null);
            });
    };




    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [selection, setSelection] = useState([]);
    const [params, setParam] = useSearchParams();
    const [status, setstatus] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });


    const columns = [
        { field: 'DeliveryPCode', headerName: 'رقم الشحنة', width: 150, headerAlign: 'left' },
        { field: 'ShopName', headerName: 'إسم المتجر', width: 250 },
        {
            field: 'RequestDate', headerName: 'التاريخ', width: 150,
            valueFormatter: (value) => {
                if (value == null) {
                    return '';
                }
                return `${dayjs(value).format('YYYY/MM/DD')}`;
            },
        },
        {
            field: 'PMethodLogo', headerName: 'نوع الدفع', width: 150,
            renderCell: (params) => <div style={{ height: '100%', display: "flex", alignItems: "center" }}>
                <img src={'images/partners/' + params.value} alt='نوع الدفع' style={{ maxHeight: '35px', marginRight: 'unset' }} /></div>
        },
        { field: 'AmountDue', headerName: 'القيمة', width: 150 },
        { field: 'ComOn', headerName: 'العمولة على', width: 150 },
        { field: 'Notes', headerName: 'ملاحظات', width: 320 },
        {
            field: 'Id', headerName: '', renderCell: (params) =>
                <div style={{ height: '100%', display: "flex", alignItems: "center" }}>
                    <Button onClick={() => handleOpenInsertNoteDialog(params)}><NotesOutlined></NotesOutlined></Button>
                </div>

        }
    ];

    useEffect(() => {
        if (params.get("status") && params.get("status") >= 1 && params.get("status") < 5) {
            setstatus(params.get("status"));
        } else {
            setParam(params => {
                const newParams = new URLSearchParams(params);
                newParams.set("status", 1);
                return newParams;
            });
        }

    }, [params, setParam]);

    useEffect(() => {

        if (!status)
            return;

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel.page, paginationModel.pageSize, status, dMethodCo]);

    const fetchData = () => {

        setLoading(true);
        const first = paginationModel.page * 10;
        const rows = paginationModel.pageSize;
        const shopId = ShopId?.id;
        const dateFrom = dayjs(DateFrom).format('YYYY/MM/DD');
        const dateTo = dayjs(DateTo).format('YYYY/MM/DD');
        let dMethod = null;

        if (isEzone() && dMethodCo)
            dMethod = dMethodCo.dMethod;

        DeliveryService.getOfflinePaymentsReact(
            {
                first,
                rows,
                ShipmentNo,
                shopId,
                dateFrom,
                dateTo,
                dMethod
            }, status)
            .finally(() => setLoading(false))
            .then(function (resp) {
                setRows(resp.data.payments);
                setRowCount(resp.data.total);
                setShops(resp.data.shops)
            });
    };


    return (
        <div className="card shipments-body">
            {status === "1" && (<h2>شحنات جديدة</h2>)}
            {status === "2" && (<h2>شحنات تحت التسوية</h2>)}
            {status === "3" && (<h2>شحنات تمت تسويتها</h2>)}
            {status === "4" && (<h2>شحنات ملغية</h2>)}

            <div className="flex justify-between items-center flex-wrap py-4 gap-2">
                <div>
                    {
                        !isEzone() &&
                        <div>
                            <Button
                                disabled={selection.length === 0 || status > 1}
                                variant="outlined"
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                خيارات
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => { setAnchorEl(null) }}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleOpenAlertDialog("isPaid")}>تم الدفع</MenuItem>
                                <MenuItem onClick={() => handleOpenAlertDialog("isCancelled")}>إلغاء الشحنة</MenuItem>
                            </Menu>
                        </div>
                    }

                    {isEzone() &&

                        <div className="flex flex-wrap gap-2">
                            <Button
                                variant="outlined"
                                disabled={selection.length === 0 || status !== '2' || !dMethodCo}
                                onClick={() => handleOpenAlertDialog("isShopPaid")}
                            >
                                تسوية
                            </Button>

                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: 220 }}
                                value={dMethodCo}
                                onChange={(event, newValue) => setDMethodCo(newValue)}
                                isOptionEqualToValue={(option, value) => option.dMethod === value.dMethod}
                                options={dMethodList}
                                size="small"
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        {option.label}
                                        <img
                                            loading="lazy"
                                            width="60"
                                            srcSet={`/images/partners/${option.label.toLowerCase()}.png 2x`}
                                            src={`/images/partners/${option.label.toLowerCase()}.png`}
                                            alt=""
                                        />

                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="شركة التوصيل"
                                    />
                                )}
                            />

                            {status === '2' && !dMethodCo && <div className="invalid-feedback">يجب إختيار شركة التوصيل للتسوية</div>}
                        </div>
                    }
                </div>

                <div className="flex gap-2 flex-wrap">
                    <TextField
                        name="ShipmentNo"
                        value={ShipmentNo}
                        onChange={(event) => setShipmentNo(event.target.value)}
                        size="small"
                        label="رقم الشحنة"
                        sx={{ width: 150 }}
                        variant="outlined" />
                    <Autocomplete
                        name="ShopId"
                        value={ShopId}
                        onChange={(event, newValue) => setShopId(newValue)}
                        size="small"
                        disablePortal
                        options={shops}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label="إسم المتجر" />}
                    />
                    <DateInput
                        label="تاريخ من"
                        value={DateFrom}
                        setValue={setDateFrom}
                    />
                    <DateInput
                        label="تاريخ إلى"
                        value={DateTo}
                        setValue={setDateTo}
                    />

                    <Button
                        variant="outlined"
                        onClick={(() => fetchData())}
                    >
                        بحث
                    </Button>
                </div>
            </div>


            <div >
                <DataGrid
                    autoHeight
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelection(newRowSelectionModel);
                    }}
                    rowSelectionModel={selection}
                    getRowId={(row) => row.Id}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    rowCount={rowCount}
                    onPaginationModelChange={setPaginationModel}
                    columns={columns}
                    rows={rows}
                    loading={loading}
                    initialState={{
                        ...rows.initialState,
                        pagination: { paginationModel },
                    }}
                    pageSizeOptions={[10, 20, 50]}
                    slots={{
                        noRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                لا توجد بيانات
                            </Stack>
                        )
                    }}
                />
            </div>

            <YesNoDialog
                open={openAlertDialog}
                handleClose={handleCloseAlertDialog}
                title="تأكيد"
                content={(() => {
                    switch (actionType) {
                        case 'isPaid':
                            return 'سيتم تحويل الشحنات للتسوية ، هل أنت متأكد ؟';
                        case 'isCancelled':
                            return 'سيتم إلغاء الشحنات ، هل أنت متأكد ؟';
                        case 'isShopPaid':
                            return 'سيتم تسوية الشحنات ، هل أنت متأكد ؟';
                        default:
                            return ''; // Or you can return a default handler function if needed
                    }
                })()}
                onConfirm={(() => {
                    switch (actionType) {
                        case 'isPaid':
                            return handleConfirmPaid;
                        case 'isCancelled':
                            return handleConfirmCancelled;
                        case 'isShopPaid':
                            return handleConfirmShopPaid;
                        default:
                            return null; // Or you can return a default handler function if needed
                    }
                })()}
            />
            <InsertDialog
                open={openInsertNoteDialog}
                handleClose={handleCloseInsertDialog}
                title="إدخال ملاحظات"
                currentNote={CurrentNote}
                onConfirm={handleConfirmInsertDialog}
            />
        </div>



    );



}