import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import { useEffect } from 'react';
import { useState } from "react";


export default function InsertDialog({ open, handleClose, title, currentNote, onConfirm }) {


    const [InputText, setInputText] = useState('');

    const handleConfirm = () => {
        onConfirm(InputText); // Call the onConfirm callback function when the user confirms
        handleClose(); // Close the dialog
    };

    useEffect(() => {
        setInputText(currentNote || '');
    }, [currentNote]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="insert-dialog-title"
            aria-describedby="insert-dialog-description"
        >
            <DialogTitle id="insert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    name="UserName"
                    value={InputText}
                    onChange={(event) => setInputText(event.target.value)}
                    label="ملاحظات"
                    variant="outlined"
                    multiline
                    rows={4}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>إلغاء الأمر</Button>
                <Button onClick={handleConfirm} autoFocus>
                    موافق
                </Button>
            </DialogActions>
        </Dialog>
    );
};